import { defineMessages } from 'react-intl';

export const scope = 'containers.EmployeeListContainer';

export default defineMessages({
  allEmployees: {
    id: `${scope}.allEmployees`,
    defaultMessage: 'All Employees',
  },
  msgInactiveBanner: {
    id: `${scope}.msgInactiveBanner`,
    defaultMessage: '{count} {user} haven’t signed up on SeaTalk',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'user',
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'users',
  },
  inviteNow: {
    id: `${scope}.inviteNow`,
    defaultMessage: 'Invite Now',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  employeeId: {
    id: `${scope}.employeeId`,
    defaultMessage: 'Employee Code',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  inPosition: {
    id: `${scope}.inPosition`,
    defaultMessage: 'In Position',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Inactive',
  },
  terminated: {
    id: `${scope}.terminated`,
    defaultMessage: 'Terminated',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: 'Department',
  },
  jobTitle: {
    id: `${scope}.jobTitle`,
    defaultMessage: 'Job Title',
  },
  officeLocation: {
    id: `${scope}.officeLocation`,
    defaultMessage: 'Office Location',
  },
  office: {
    id: `${scope}.office`,
    defaultMessage: 'Office',
  },
  reportTo: {
    id: `${scope}.reportTo`,
    defaultMessage: 'Report to',
  },
  joinDate: {
    id: `${scope}.joinDate`,
    defaultMessage: 'Join Date',
  },
  addEmployee: {
    id: `${scope}.addEmployee`,
    defaultMessage: 'Add Employee',
  },
  editEmployee: {
    id: `${scope}.editEmployee`,
    defaultMessage: 'Edit Employee',
  },
  massUpdate: {
    id: `${scope}.massUpdate`,
    defaultMessage: 'Mass Update',
  },
  massDelete: {
    id: `${scope}.massDelete`,
    defaultMessage: 'Mass Delete',
  },
  quickAction: {
    id: `${scope}.quickAction`,
    defaultMessage: 'Quick Action',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  countEmployeesAreSuccessfullyUpdated: {
    id: `${scope}.countEmployeesAreSuccessfullyUpdated`,
    defaultMessage: '{count} employees are successfully updated',
  },
  batchImportEdit: {
    id: `${scope}.batchImportEdit`,
    defaultMessage: 'Batch Import / Edit',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: 'Import',
  },
  exportAll: {
    id: `${scope}.exportAll`,
    defaultMessage: 'Export All',
  },
  exportSelected: {
    id: `${scope}.exportSelected`,
    defaultMessage: 'Export Selected ({count})',
  },
  importNewEmployee: {
    id: `${scope}.importNewEmployee`,
    defaultMessage: 'Import New Employees',
  },
  updateExistingEmployee: {
    id: `${scope}.updateExistingEmployee`,
    defaultMessage: 'Update Existing Employees',
  },
  emailNotifyImport: {
    id: `${scope}.emailNotifyImport`,
    defaultMessage: 'Email notificaton to new employees',
  },
  msg2: {
    id: `${scope}.msg2`,
    defaultMessage:
      'You can only import new employees using this function. If you want to edit existing employee information, please utilize the mass update function.',
  },
  operatingOn: {
    id: `${scope}.operatingOn`,
    defaultMessage: 'You are operating on the {number} selected employees:',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  fieldName: {
    id: `${scope}.fieldName`,
    defaultMessage: 'Field Name',
  },
  msgFieldName: {
    id: `${scope}.msgFieldName`,
    defaultMessage: 'Select the field that you want to update',
  },
  newValue: {
    id: `${scope}.newValue`,
    defaultMessage: 'New Value',
  },
  resetDepartment: {
    id: `${scope}.resetDepartment`,
    defaultMessage: 'Reset current department',
  },
  overrideDepartment: {
    id: `${scope}.overrideDepartment`,
    defaultMessage: 'Override current department',
  },
  appendDepartment: {
    id: `${scope}.appendDepartment`,
    defaultMessage: 'Add current department',
  },
  msgMassDelete: {
    id: `${scope}.msgMassDelete`,
    defaultMessage:
      'Are you sure to remove {number} employees including {names}? All deleted data cannot be restored.',
  },
  msgAndOthers: {
    id: `${scope}.msgAndOthers`,
    defaultMessage: 'and others',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  deleteEmployee: {
    id: `${scope}.deleteEmployee`,
    defaultMessage: 'Delete Employee',
  },
  invite: {
    id: `${scope}.invite`,
    defaultMessage: 'Invite',
  },
  employeeDeletedSuccessfuly: {
    id: `${scope}.employeeDeletedSuccessfuly`,
    defaultMessage: 'Employee deleted successfuly',
  },
  emailInvitationSentSuccessfuly: {
    id: `${scope}.emailInvitationSentSuccessfuly`,
    defaultMessage: 'Email invitation sent successfuly',
  },
  employeesDeletedSuccessfuly: {
    id: `${scope}.employeesDeletedSuccessfuly`,
    defaultMessage: 'Employees deleted successfuly',
  },
  msgInvite: {
    id: `${scope}.msgInvite`,
    defaultMessage:
      'There are {number} inactive employees in your organization. You may send invitation to them by email.',
  },
  msgDelete: {
    id: `${scope}.msgDelete`,
    defaultMessage: 'Are you sure to delete {name}?',
  },
  msgDeleteHint: {
    id: `${scope}.msgDeleteHint`,
    defaultMessage:
      'If the employee belongs to any department that is out of your management, he/she will only be removed from the department under your management.',
  },
  msgOffboardRecommendedHint: {
    id: `${scope}.msgOffboardRecommendedHint`,
    defaultMessage:
      'This employee was assigned system roles or has pending task. We recommend you to offboard employee instead of deleting.',
  },
  enterName: {
    id: `${scope}.enterName`,
    defaultMessage: 'Enter Name',
  },
  enterEmail: {
    id: `${scope}.enterEmail`,
    defaultMessage: 'Enter Email',
  },
  enterPhoneNo: {
    id: `${scope}.enterPhoneNo`,
    defaultMessage: 'Enter Phone No.',
  },
  phoneNo: {
    id: `${scope}.phoneNo`,
    defaultMessage: 'Phone No.',
  },
  photo: {
    id: `${scope}.photo`,
    defaultMessage: 'Photo',
  },
  work: {
    id: `${scope}.work`,
    defaultMessage: 'Work',
  },
  probationEndDate: {
    id: `${scope}.probationEndDate`,
    defaultMessage: 'Probation End Date',
  },
  terminationDate: {
    id: `${scope}.terminationDate`,
    defaultMessage: 'Termination Date',
  },
  joiningDate: {
    id: `${scope}.joiningDate`,
    defaultMessage: 'Joining Date',
  },
  selectProbationEndDate: {
    id: `${scope}.selectProbationEndDate`,
    defaultMessage: 'Select Probation End Date',
  },
  selectTerminationDate: {
    id: `${scope}.selectTerminationDate`,
    defaultMessage: 'Select Termination Date',
  },
  selectJoiningDate: {
    id: `${scope}.selectJoiningDate`,
    defaultMessage: 'Select Joining Date',
  },
  payrollCompany: {
    id: `${scope}.payrollCompany`,
    defaultMessage: 'Payroll Company',
  },
  employeeType: {
    id: `${scope}.employeeType`,
    defaultMessage: 'Employee Type',
  },
  rank: {
    id: `${scope}.rank`,
    defaultMessage: 'Rank',
  },
  selectEmployeeType: {
    id: `${scope}.selectEmployeeType`,
    defaultMessage: 'Select Employee Type',
  },
  fullTime: {
    id: `${scope}.fullTime`,
    defaultMessage: 'Full Time',
  },
  partTime: {
    id: `${scope}.partTime`,
    defaultMessage: 'Part Time',
  },
  shiftWorker: {
    id: `${scope}.shiftWorker`,
    defaultMessage: 'Shift Worker',
  },
  contractor: {
    id: `${scope}.contractor`,
    defaultMessage: 'Contractor',
  },
  internship: {
    id: `${scope}.internship`,
    defaultMessage: 'Internship',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending',
  },
  leaving: {
    id: `${scope}.leaving`,
    defaultMessage: 'Leaving',
  },
  employeeStatus: {
    id: `${scope}.employeeStatus`,
    defaultMessage: 'Employee Status',
  },
  selectEmployeeStatus: {
    id: `${scope}.selectEmployeeStatus`,
    defaultMessage: 'Select Employee Status',
  },
  enterStaffId: {
    id: `${scope}.enterStaffId`,
    defaultMessage: 'Enter Employee ID',
  },
  employeeIdTip: {
    id: `${scope}.employeeIdTip`,
    defaultMessage:
      'A unique code to identify the employee in the organization. E.g. staff badge number, etc.',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  selectGender: {
    id: `${scope}.selectGender`,
    defaultMessage: 'Select Gender',
  },
  male: {
    id: `${scope}.male`,
    defaultMessage: 'Male',
  },
  female: {
    id: `${scope}.female`,
    defaultMessage: 'Female',
  },
  others: {
    id: `${scope}.others`,
    defaultMessage: 'Others',
  },
  personal: {
    id: `${scope}.personal`,
    defaultMessage: 'Personal',
  },
  maritalStatus: {
    id: `${scope}.maritalStatus`,
    defaultMessage: 'Marital Status',
  },
  selectMaritalStatus: {
    id: `${scope}.selectMaritalStatus`,
    defaultMessage: 'Select Marital Status',
  },
  single: {
    id: `${scope}.single`,
    defaultMessage: 'Single',
  },
  married: {
    id: `${scope}.married`,
    defaultMessage: 'Married',
  },
  divorced: {
    id: `${scope}.divorced`,
    defaultMessage: 'Divorced',
  },
  widowed: {
    id: `${scope}.widowed`,
    defaultMessage: 'Widowed',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  enterAddress: {
    id: `${scope}.enterAddress`,
    defaultMessage: 'Enter Address',
  },
  dateOfBirth: {
    id: `${scope}.dateOfBirth`,
    defaultMessage: 'Date of Birth',
  },
  selectDateOfBirth: {
    id: `${scope}.selectDateOfBirth`,
    defaultMessage: 'Select Date of Birth',
  },
  noOfChildren: {
    id: `${scope}.noOfChildren`,
    defaultMessage: 'No. of Children',
  },
  enterNoOfChildren: {
    id: `${scope}.enterNoOfChildren`,
    defaultMessage: 'Enter No of Children',
  },
  personalEmail: {
    id: `${scope}.personalEmail`,
    defaultMessage: 'Personal Email',
  },
  enterPersonalEmail: {
    id: `${scope}.enterPersonalEmail`,
    defaultMessage: 'Enter Personal Email',
  },
  probationEndDateMustBeAfterJoiningDate: {
    id: `${scope}.probationEndDateMustBeAfterJoiningDate`,
    defaultMessage: 'Probation End Date must be after Joining Date',
  },
  resignDateMustBeAfterJoiningDate: {
    id: `${scope}.resignDateMustBeAfterJoiningDate`,
    defaultMessage: 'Termination Date must be after Joining Date',
  },
  addNext: {
    id: `${scope}.addNext`,
    defaultMessage: 'Add & Next',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  nameCannotBeEmpty: {
    id: `${scope}.nameCannotBeEmpty`,
    defaultMessage: 'Name cannot be empty',
  },
  emailCannotBeEmpty: {
    id: `${scope}.emailCannotBeEmpty`,
    defaultMessage: 'Email cannot be empty',
  },
  departmentCannotBeEmpty: {
    id: `${scope}.departmentCannotBeEmpty`,
    defaultMessage: 'Department cannot be empty',
  },
  pendingEmailDuplicationError: {
    id: `${scope}.pendingEmailDuplicationError`,
    defaultMessage:
      'Another employee with this email already submitted a request',
  },
  existingEmailDuplicationError: {
    id: `${scope}.existingEmailDuplicationError`,
    defaultMessage: 'The work email already exists',
  },
  employeeIdDuplicationError: {
    id: `${scope}.employeeIdDuplicationError`,
    defaultMessage: 'The Employee Code already exists',
  },
  employeeAddedSuccessfully: {
    id: `${scope}.employeeAddedSuccessfully`,
    defaultMessage: 'Employee added successfully',
  },
  employeeUpdatedSuccessfully: {
    id: `${scope}.employeeUpdatedSuccessfully`,
    defaultMessage: 'Employee updated successfully',
  },
  invalidEmail: {
    id: `${scope}.invalidEmail`,
    defaultMessage: 'Invalid email',
  },
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Invalid phone number',
  },
  seaTalkId: {
    id: `${scope}.seaTalkId`,
    defaultMessage: 'SeaTalk ID',
  },
  seaTalkAccount: {
    id: `${scope}.seaTalkAccount`,
    defaultMessage: 'SeaTalk Account',
  },
  seaTalkNickname: {
    id: `${scope}.seaTalkNickname`,
    defaultMessage: 'SeaTalk Nickname: {nickname}',
  },
  lengthOfService: {
    id: `${scope}.lengthOfService`,
    defaultMessage: 'Length of Service',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  exportFinished: {
    id: `${scope}.exportFinished`,
    defaultMessage: 'Export finished',
  },
  exportFailed: {
    id: `${scope}.exportFailed`,
    defaultMessage: 'Export failed',
  },
  noEmployee: {
    id: `${scope}.noEmployee`,
    defaultMessage: 'No Employee',
  },
  emptyEmployee: {
    id: `${scope}.emptyEmployee`,
    defaultMessage: 'There is no employee added for current organization.',
  },
  noticeNotice: {
    id: `${scope}.noticeNotice`,
    defaultMessage: 'Notice',
  },
  deleteEmployeeOwnerNotice: {
    id: `${scope}.deleteEmployeeOwnerNotice`,
    defaultMessage:
      'This employee is the owner of department groups for the following departments: {departments}. Please transfer the ownership to other members first before deletion',
  },
  nameIsGroupOwnerOf: {
    id: `${scope}.nameIsGroupOwnerOf`,
    defaultMessage: '{name} is the department group owner of {departments}.',
  },
  pleaseTransferOwnership: {
    id: `${scope}.pleaseTransferOwnership`,
    defaultMessage:
      'Please transfer the ownership to other members first before deletion.',
  },
  gotIt: {
    id: `${scope}.gotIt`,
    defaultMessage: 'Got it',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  importEmployees: {
    id: `${scope}.importEmployees`,
    defaultMessage: 'Import Employees',
  },
  importNewEmployeeDescription: {
    id: `${scope}.importNewEmployeeDescription`,
    defaultMessage: `You can update employees’ information using this function or utilize the quick action function. If you want to update existing employees' information, please toggle to the other tab.`,
  },
  downloadTemplate: {
    id: `${scope}.downloadTemplate`,
    defaultMessage: 'Download Template',
  },
  downloadSuccess: {
    id: `${scope}.downloadSuccess`,
    defaultMessage: 'Download Success',
  },
  downloadAgain: {
    id: `${scope}.downloadAgain`,
    defaultMessage: 'Download Again',
  },
  importNewEmployeeStep1Title: {
    id: `${scope}.importNewEmployeeStep1Title`,
    defaultMessage: 'Step 1. Download the template',
  },
  importNewEmployeeStep1Description: {
    id: `${scope}.importNewEmployeeStep1Description`,
    defaultMessage: '{template} and follow instructions to fill in.',
  },
  importNewEmployeeStep2Title: {
    id: `${scope}.importNewEmployeeStep2Title`,
    defaultMessage: 'Step 2. Upload completed table',
  },
  importNewEmployeeStep2Description: {
    id: `${scope}.importNewEmployeeStep2Description`,
    defaultMessage:
      'Upon data import failure, the system will return an excel file, where you can download to check the reason of failure.',
  },
  browse: {
    id: `${scope}.browse`,
    defaultMessage: 'Browse',
  },
  browseOrDragToUpload: {
    id: `${scope}.browseOrDragToUpload`,
    defaultMessage: '{highlightedText} or drag a file to this area to upload',
  },
  supportType: {
    id: `${scope}.supportType`,
    defaultMessage: 'Only support {extension}, max {size}MB',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  updateExistingEmployeeDescription: {
    id: `${scope}.updateExistingEmployeeDescription`,
    defaultMessage:
      'You can update employees’ information using this function or utilize the quick action function. If you want to batch import new employees information, please toggle to the other tab.',
  },
  updateExistingEmployeeStep1Title: {
    id: `${scope}.updateExistingEmployeeStep1Title`,
    defaultMessage: 'Step 1. Export Employees’ Information',
  },
  exportSuccess: {
    id: `${scope}.exportSuccess`,
    defaultMessage: 'Export Successfully',
  },
  exportAgain: {
    id: `${scope}.exportAgain`,
    defaultMessage: 'Select & Export Again',
  },
  selectExportEmployees: {
    id: `${scope}.selectExportEmployees`,
    defaultMessage: 'Select & Export Employees',
  },
  updateExistingEmployeeStep1Description: {
    id: `${scope}.updateExistingEmployeeStep1Description`,
    defaultMessage: '{template} and follow instructions to fill in.',
  },
  selectEmployeeToExport: {
    id: `${scope}.selectEmployeeToExport`,
    defaultMessage: 'Select Employee to Export',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  importingData: {
    id: `${scope}.importingData`,
    defaultMessage: 'Importing data',
  },
  goToCheck: {
    id: `${scope}.goToCheck`,
    defaultMessage: 'Go to Check',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing...',
  },
  transferFromOtherDepartment: {
    id: `${scope}.transferFromOtherDepartment`,
    defaultMessage: 'Transfer from Other Department',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  resetEmployeeCurrentDepartment: {
    id: `${scope}.resetEmployeeCurrentDepartment`,
    defaultMessage: `Reset employee's current department`,
  },
  addToEmployeeCurrentDepartment: {
    id: `${scope}.addToEmployeeCurrentDepartment`,
    defaultMessage: `Add to employee's current department`,
  },
  uploadAgain: {
    id: `${scope}.uploadAgain`,
    defaultMessage: 'Upload Again',
  },
  importFail: {
    id: `${scope}.importFail`,
    defaultMessage:
      '{success} item(s) imported successfully, {failed} item(s) failed to import',
  },
  importSuccess: {
    id: `${scope}.importSuccess`,
    defaultMessage: '{success} item(s) imported successfully.',
  },
  errorOccuredWhileImporting: {
    id: `${scope}.errorOccuredWhileImporting`,
    defaultMessage: 'Import failed. Please try to upload again.',
  },
  importTemplateError: {
    id: `${scope}.importTemplateError`,
    defaultMessage: 'Template not matched. Import failed.',
  },
  downloadFailedData: {
    id: `${scope}.downloadFailedData`,
    defaultMessage: 'Click to download failed data',
  },
  departmentCode: {
    id: `${scope}.departmentCode`,
    defaultMessage: 'Department Code: {code}',
  },
  exportEmployee: {
    id: `${scope}.exportEmployee`,
    defaultMessage: 'Export Employee',
  },
  exportWarning: {
    id: `${scope}.exportWarning`,
    defaultMessage:
      '{excluded} employee(s) are excluded because you do not have export permission for their department. Continue to export the following {exportable} employee(s)?',
  },
  uploadFailed: {
    id: `${scope}.uploadFailed`,
    defaultMessage: 'Upload failed, please try uploading again',
  },
  uploadingAndMatching: {
    id: `${scope}.uploadingAndMatching`,
    defaultMessage: 'Uploading and matching ..., please do not leave this page',
  },
  terminatedEmployee: {
    id: `${scope}.terminatedEmployee`,
    defaultMessage: 'Terminated Employee',
  },
  offboard: {
    id: `${scope}.offboard`,
    defaultMessage: 'Offboard',
  },
  cancelOffboarding: {
    id: `${scope}.cancelOffboarding`,
    defaultMessage: 'Cancel Offboarding',
  },
  offboardingTime: {
    id: `${scope}.offboardingTime`,
    defaultMessage: 'Offboarding Time',
  },
  selectOffboardingTime: {
    id: `${scope}.selectOffboardingTime`,
    defaultMessage: 'Select Offboarding Time',
  },
  historyOfArrangement: {
    id: `${scope}.historyOfArrangement`,
    defaultMessage: 'History of Arrangement',
  },
  reboard: {
    id: `${scope}.reboard`,
    defaultMessage: 'Re-Board',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Basic Info',
  },
  workInfo: {
    id: `${scope}.workInfo`,
    defaultMessage: 'Work Info',
  },
  personalInfo: {
    id: `${scope}.personalInfo`,
    defaultMessage: 'Personal Info',
  },
  customFields: {
    id: `${scope}.customFields`,
    defaultMessage: 'Custom Fields',
  },
  employeeDetails: {
    id: `${scope}.employeeDetails`,
    defaultMessage: 'Employee Details',
  },
  organization: {
    id: `${scope}.organization`,
    defaultMessage: 'Organization',
  },
  customFieldsLinkEntry: {
    id: `${scope}.customFieldsLinkEntry`,
    defaultMessage: 'Link Entry Icon/Text',
  },
  customFieldsLinkEntryDescription: {
    id: `${scope}.customFieldsLinkEntryDescription`,
    defaultMessage:
      'The link entry icon/text can only be configured in the backend.',
  },
  employeeNameTooLong: {
    id: `${scope}.employeeNameTooLong`,
    defaultMessage: 'Employee name must be between 0 and 255 characters',
  },
  employeeIdTooLong: {
    id: `${scope}.employeeIdTooLong`,
    defaultMessage: 'Employee Code cannot be more than 64 characters',
  },
  employeeAddressTooLong: {
    id: `${scope}.employeeAddressTooLong`,
    defaultMessage: 'Employee address must be between 0 and 255 characters',
  },
  employeeEmailTooLong: {
    id: `${scope}.employeeEmailTooLong`,
    defaultMessage:
      'Company email address must be between 0 and 254 characters',
  },
  employeePersonalEmailTooLong: {
    id: `${scope}.employeePersonalEmailTooLong`,
    defaultMessage:
      'Personal email address must be between 0 and 254 characters',
  },
  cyclicReportingRelationWarning: {
    id: `${scope}.cyclicReportingRelationWarning`,
    defaultMessage:
      'We are unable to update the reporting managers for the following employee(s):\n\n{names}\n\ndue to cyclic reporting relationship being formed. Please double check and update the reporting information again.\n\nAll other fields were updated successfully.',
  },
  insufficientPermissionToDelete: {
    id: `${scope}.insufficientPermissionToDelete`,
    defaultMessage: 'Insufficient permission to delete {name}.',
  },
  areDepartmentOwnersOf: {
    id: `${scope}.areDepartmentOwnersOf`,
    defaultMessage: '{name} are department group owners of {departments}.',
  },
  unableToDeleteEmployees: {
    id: `${scope}.unableToDeleteEmployees`,
    defaultMessage: 'Unable to delete the selected employees. ',
  },
  employeesPartiallyDeleted: {
    id: `${scope}.employeesPartiallyDeleted`,
    defaultMessage: 'Employees are partially deleted. ',
  },
  pleaseCheckBeforeDeletion: {
    id: `${scope}.pleaseCheckBeforeDeletion`,
    defaultMessage:
      'Please check if you have sufficient permission to delete or they are department group owners for the following employees.',
  },
  profilePicture: {
    id: `${scope}.profilePicture`,
    defaultMessage: 'Profile Picture',
  },
  profilePictureTip: {
    id: `${scope}.profilePictureTip`,
    defaultMessage: `Profile Picture can be updated anytime. If left blank, employee's SeaTalk picture will be used as default`,
  },
  year: {
    id: `${scope}.year`,
    defaultMessage: 'Year',
  },
  invalidDate: {
    id: `${scope}.invalidDate`,
    defaultMessage: 'Invalid date',
  },
  lackDate: {
    id: `${scope}.lackDate`,
    defaultMessage: 'Lack of date',
  },
  customInfo: {
    id: 'containers.OrgConfiguration.customInfo',
    defaultMessage: 'Custom Info',
  },
  enter: {
    id: 'components.Common.enter',
    defaultMessage: 'Enter',
  },
});
