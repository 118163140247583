/** GLOBAL SETTINGS & SERVER ENUMS */

export const CLAIM_EXCLUSION = ['1007'];
export const LEAVE_EXCLUSION = [];
// special code, organizationCode === companyId
export const APP_CENTER_EXCLUSION = [];

export const IS_LIVE =
  import.meta.env.BUILD_ENV === 'live' ||
  import.meta.env.BUILD_ENV === 'liveish';

export const SUPER_ADMIN_ROLE_ID = IS_LIVE ? 2 : 4;

export const MAXIMUM_DATE = '9999-12-31';

export const MB = 1024 * 1024;

export const SETTING = {
  apiUrlPrefix: '/api',
  gfsUrlPrefix:
    import.meta.env.BUILD_ENV === 'live'
      ? 'https://f.haiserve.com'
      : 'https://f.test.haiserve.com',
  searchDebounce: 400,
  defaultPageSize: 20,
};

export const MAX_UPLOAD_FILE_SIZE = {
  EXCEL: 1,
  IMAGE: 20,
};

export const COMPANY_VISIBILITY = {
  PRIVATE: 0,
  PUBLIC: 1,
};

export const DEPARTMENT_VISIBILITY_LEVEL = {
  ALL: 0,
  SUBORDINATE: 1,
  WHITELIST: 2,
};

export const DOMAIN_STATUS = {
  VALIDATING: 0,
  INUSE: 1,
};

export const ROLE_SCOPE = {
  ALL: 0,
  SUBORDINATE: 1,
};

export const GENDER = {
  BLANK: 0,
  MALE: 1,
  FEMALE: 2,
  UNKNOWN: 3,
};

export const MARITAL_STATUS = {
  BLANK: 0,
  SINGLE: 1,
  MARRIED: 2,
  DIVORCED: 3,
  WIDOWED: 4,
  UNKNOWN: 5,
};

export const QUOTA_ENUM = {
  FRIEND_LIST_MAX: 1,
  GROUP_MEMBERS_MAX: 2,
  SUPER_ADMIN_MAX: import.meta.env.BUILD_ENV === 'live' ? 10 : 15,
};

export const WEEK_DISPLAY_INDICATOR = {
  UNCHECKED: 0,
  AM: 1,
  PM: 2,
  FULL: 3,
};

export const COMPANY_VISIBILITY_LEVEL = {
  PRIVATE: 0,
  PUBLIC: 1,
};

export const DEPARTMENT_SCOPE_PURPOSE = {
  DEFAULT: -1,
  ADD_DEPARTMENT: 1,
  EDIT_DEPARTMENT: 2,
  DELETE_DEPARTMENT: 3,
  VIEW_DEPARTMENT: 4, // Including view employees purpose
  ADD_EMPLOYEE: 5,
  DELETE_EMPLOYEE: 6,
  EDIT_EMPLOYEE: 7,
  UPLOAD_EMPLOYEE_PHOTO: 8,
  MANAGE_PUBLIC_ACCOUNT: 9,
  VIEW_EMPLOYEE_PROFILE: 10,
  EXPORT_EMPLOYEE: 11,
  APP_CENTER: 12,
  APP_CENTER_DEVELOPER: 13,
  CLAIM_APP: 14,
  LEAVE_ATTENDANCE_APP: 15,
  CHECK_IN_APP: 16,
};

export const WORKGROUP_SCOPE_PURPOSE = {
  APPROVAL_CONDITION: 0,
  FILTER_SUMMARY: 1,
};

export const OTP_GENERATION_PURPOSE = {
  MAIN_SUPER_ADMIN_TRANSFER: 1,
};

export const ROLE_TYPE = {
  GENERAL_ROLE: 1,
  SUPER_ADMIN: 2,
};

export const USER_HINT_PURPOSE = {
  ROLE_SUPER_ADMIN: 1,
  ROLE_HIGHER_RANK: 2,
};

export const PREDEFINED_ROLE_TYPE = {
  NONE: 0,
  SUPER_ADMIN: 1,
  HR_MANAGER: 2,
  PUBLIC_ACCOUNT_MANAGER: 3,
};

export const EMPLOYEE_TYPE = {
  BLANK: 0,
  FULL_TIME: 1,
  PART_TIME: 2,
  SHIFT_WORKER: 3,
  CONTRACTOR: 4,
  INTERNSHIP: 5,
  OTHER: 6,
};

export const EMPLOYEE_STATUS = {
  BLANK: 0,
  PENDING: 1,
  ACTIVE: 2,
  LEAVING: 3,
  INACTIVE: 4,
};

export const ORDER_BY_KEY = {
  UPDATED_AT: 'updated_at',
};

export const IC_TYPE = {
  BLANK: 0,
  NRIC: 1,
  FIN: 2,
  IMMIGRATION_FILE_NO: 3,
  WORK_PERMIT: 4,
  MALAYSIAN_IC: 5,
  PASSPORT: 6,
};

export const CALENDAR_DAY_STATUS = {
  WORKING_DAY: 1,
  WEEKEND: 2,
  HOLIDAY: 3,
  APPROVED_LEAVE: 4,
  PENDING_LEAVE: 5,
};

export const PERMISSION = {
  HOME_VIEW: 'UI_HOME_VIEW', // not in use
  EMPLOYEE_VIEW: 'UI_EMPLOYEE_VIEW',
  EMPLOYEE_ADD: 'UI_EMPLOYEE_ADD',
  EMPLOYEE_EDIT: 'UI_EMPLOYEE_EDIT', // not in use, the permission is per department record
  EMPLOYEE_DELETE: 'UI_EMPLOYEE_DEL', // not in use, the permission is per department record
  EMPLOYEE_PHOTO_UPLOAD: 'UI_EMPLOYEE_UPLOAD_PHOTO',
  EMPLOYEE_EXPORT: 'UI_EMPLOYEE_EXPORT',
  EMPLOYEE_IMPORT: 'UI_EMPLOYEE_IMPORT',
  EMPLOYEE_MASS_UPDATE: 'UI_EMPLOYEE_MASS_UPDATE',
  EMPLOYEE_MASS_DELETE: 'UI_EMPLOYEE_MASS_DEL',
  DEPARTMENT_VIEW: 'UI_DEPARTMENT_VIEW',
  DEPARTMENT_ADD: 'UI_DEPARTMENT_ADD',
  DEPARTMENT_EDIT: 'UI_DEPARTMENT_EDIT', // not in use, the permission is per department record
  DEPARTMENT_DELETE: 'UI_DEPARTMENT_DEL', // not in use, the permission is per department record
  JOB_TITLE_VIEW: 'UI_ORG_JOB_TITLE_VIEW',
  JOB_TITLE_ADD: 'UI_ORG_JOB_TITLE_ADD',
  JOB_TITLE_EDIT: 'UI_ORG_JOB_TITLE_EDIT',
  JOB_TITLE_DELETE: 'UI_ORG_JOB_TITLE_DEL',
  OFFICE_VIEW: 'UI_ORG_OFFICE_VIEW',
  OFFICE_ADD: 'UI_ORG_OFFICE_ADD',
  OFFICE_EDIT: 'UI_ORG_OFFICE_EDIT',
  OFFICE_DELETE: 'UI_ORG_OFFICE_DEL',
  PAYROLL_VIEW: 'UI_ORG_PAYROLL_COMPANY_VIEW',
  PAYROLL_ADD: 'UI_ORG_PAYROLL_COMPANY_ADD',
  PAYROLL_EDIT: 'UI_ORG_PAYROLL_COMPANY_EDIT',
  PAYROLL_DELETE: 'UI_ORG_PAYROLL_COMPANY_DEL',
  RANK_VIEW: 'UI_ORG_RANK_VIEW',
  RANK_ADD: 'UI_ORG_RANK_ADD',
  RANK_EDIT: 'UI_ORG_RANK_EDIT',
  RANK_DELETE: 'UI_ORG_RANK_DEL',
  COMPANY_ADD: 'UI_COMPANY_ADD',
  COMPANY_EDIT: 'UI_COMPANY_EDIT',
  COMPANY_DELETE: 'UI_COMPANY_DEL', // not in use
  SETTING_GENERAL_VIEW: 'UI_SETTING_GENERAL_VIEW',
  SETTING_GENERAL_EDIT: 'UI_SETTING_GENERAL_EDIT',
  DOMAIN_VIEW: 'UI_SETTING_DOMAIN_VIEW',
  DOMAIN_ADD: 'UI_SETTING_DOMAIN_ADD',
  DOMAIN_EDIT: 'UI_SETTING_DOMAIN_EDIT',
  DOMAIN_DELETE: 'UI_SETTING_DOMAIN_DEL',
  ADMIN_VIEW: 'UI_SETTING_ACCESS_ADMIN_VIEW',
  ADMIN_ADD: 'UI_SETTING_ACCESS_ADMIN_ADD',
  ADMIN_TOGGLE: 'UI_SETTING_ACCESS_ADMIN_TOGGLE',
  ADMIN_ROLE_ASSIGN: 'UI_SETTING_ACCESS_ADMIN_ROLE_ASSIGN',
  ADMIN_DELETE: 'UI_SETTING_ACCESS_ADMIN_DEL',
  ROLE_VIEW: 'UI_SETTING_ACCESS_ROLE_VIEW',
  ROLE_ADD: 'UI_SETTING_ACCESS_ROLE_ADD',
  ROLE_EDIT: 'UI_SETTING_ACCESS_ROLE_EDIT',
  ROLE_DELETE: 'UI_SETTING_ACCESS_ROLE_DEL',
  ROLE_TRANSFER: 'UI_SETTING_ACCESS_ROLE_TRANSFER',
  SETTING_OTHERS_VIEW: 'UI_SETTING_OTHERS_VIEW',
  SETTING_OTHERS_EDIT: 'UI_SETTING_OTHERS_EDIT', // not applied
  PUBLIC_ACCOUNT_VIEW: 'UI_PUBLIC_ACCOUNT_VIEW_MINE',
  PUBLIC_ACCOUNT_VIEW_ALL: 'UI_PUBLIC_ACCOUNT_VIEW_ALL',
  PUBLIC_ACCOUNT_ADD: 'UI_PUBLIC_ACCOUNT_ADD',
  PUBLIC_ACCOUNT_EDIT: 'UI_PUBLIC_ACCOUNT_EDIT',
  PUBLIC_ACCOUNT_DELETE: 'UI_PUBLIC_ACCOUNT_DEL',
  PUBLIC_ACCOUNT_BROADCAST_VIEW: 'UI_PUBLIC_ACCOUNT_BROADCAST_VIEW', // not in use
  PUBLIC_ACCOUNT_BROADCAST_MANAGE: 'UI_PUBLIC_ACCOUNT_BROADCAST_MANAGE',
  LEAVE_CALENDAR_VIEW: 'UI_CALENDAR_VIEW',
  LEAVE_CALENDAR_ADD: 'UI_CALENDAR_ADD',
  LEAVE_CALENDAR_EDIT: 'UI_CALENDAR_EDIT',
  LEAVE_CALENDAR_DEL: 'UI_CALENDAR_DEL',
  LEAVE_TYPE_RULE_VIEW: 'UI_LEAVE_TYPE_RULE_VIEW',
  LEAVE_TYPE_RULE_ADD: 'UI_LEAVE_TYPE_RULE_ADD',
  LEAVE_TYPE_RULE_EDIT: 'UI_LEAVE_TYPE_RULE_EDIT',
  LEAVE_TYPE_RULE_DEL: 'UI_LEAVE_TYPE_RULE_DEL',
  LEAVE_PREFERENCE_VIEW: 'UI_LEAVE_PREFERENCE_VIEW',
  LEAVE_PREFERENCE_EDIT: 'UI_LEAVE_PREFERENCE_EDIT',
  LEAVE_POLICY_VIEW: 'UI_LEAVE_POLICY_VIEW',
  LEAVE_POLICY_ADD: 'UI_LEAVE_POLICY_ADD',
  LEAVE_POLICY_EDIT: 'UI_LEAVE_POLICY_EDIT',
  LEAVE_POLICY_DEL: 'UI_LEAVE_POLICY_DEL',
  LEAVE_APPROVAL_VIEW: 'UI_LEAVE_APPROVAL_VIEW',
  LEAVE_APPROVAL_ADD: 'UI_LEAVE_APPROVAL_ADD',
  LEAVE_APPROVAL_DEL: 'UI_LEAVE_APPROVAL_DEL',
  LEAVE_CREDIT_APPROVAL_VIEW: 'UI_LEAVE_CREDIT_APPROVAL_VIEW',
  LEAVE_CREDIT_APPROVAL_ADD: 'UI_LEAVE_CREDIT_APPROVAL_ADD',
  LEAVE_CREDIT_APPROVAL_DEL: 'UI_LEAVE_CREDIT_APPROVAL_DEL',
  LEAVE_BALANCE_VIEW: 'UI_LEAVE_BALANCE_VIEW',
  LEAVE_BALANCE_EDIT: 'UI_LEAVE_BALANCE_EDIT',
  LEAVE_BALANCE_IMPORT: 'UI_LEAVE_BALANCE_IMPORT',
  LEAVE_BALANCE_ADJUST_IMPORT: 'UI_LEAVE_BALANCE_ADJUST_IMPORT',
  LEAVE_APPLICATION_VIEW: 'UI_LEAVE_APPLICATION_VIEW',
  LEAVE_CREDIT_APPLICATION_VIEW: 'UI_LEAVE_CREDIT_APPLICATION_VIEW',
  LEAVE_CREDIT_APPLICATION_EDIT: 'UI_LEAVE_CREDIT_APPLICATION_EDIT',
  WORK_HOLIDAY_SETTING_VIEW: 'UI_WORK_HOLIDAY_SETTING_VIEW',
  WORK_HOLIDAY_SETTING_EDIT: 'UI_WORK_HOLIDAY_SETTING_EDIT',
  WORK_HOLIDAY_SETTING_IMPORT: 'UI_WORK_HOLIDAY_SETTING_IMPORT',
  WORK_GROUP_VIEW: 'UI_WORK_GROUP_VIEW',
  WORK_GROUP_EDIT: 'UI_WORK_GROUP_EDIT',
  WORK_GROUP_MANAGER: 'UI_WORK_GROUP_MANAGER',
  WORK_SHIFT_VIEW: 'UI_WORK_SHIFT_VIEW',
  WORK_SHIFT_EDIT: 'UI_WORK_SHIFT_EDIT',
  ATTENDANCE_APPROVAL_VIEW: 'UI_ATTENDANCE_APPROVAL_VIEW',
  ATTENDANCE_APPROVAL_EDIT: 'UI_ATTENDANCE_APPROVAL_EDIT',
  ATTENDANCE_SUMMARY_VIEW: 'UI_ATTENDANCE_SUMMARY_VIEW',
  ATTENDANCE_SUMMARY_EDIT: 'UI_ATTENDANCE_SUMMARY_EDIT',
  ATTENDANCE_SUMMARY_IMPORT: 'UI_ATTENDANCE_SUMMARY_IMPORT',
  CLAIM_CATEGORY_VIEW: 'UI_CLAIM_CATEGORY_VIEW',
  CLAIM_CATEGORY_ADD: 'UI_CLAIM_CATEGORY_ADD',
  CLAIM_CATEGORY_EDIT: 'UI_CLAIM_CATEGORY_EDIT',
  CLAIM_CATEGORY_DEL: 'UI_CLAIM_CATEGORY_DEL',
  CLAIM_POLICY_VIEW: 'UI_CLAIM_POLICY_VIEW',
  CLAIM_POLICY_ADD: 'UI_CLAIM_POLICY_ADD',
  CLAIM_POLICY_EDIT: 'UI_CLAIM_POLICY_EDIT',
  CLAIM_POLICY_DEL: 'UI_CLAIM_POLICY_DEL',
  CLAIM_CURRENCY_VIEW: 'UI_CLAIM_CURRENCY_VIEW',
  CLAIM_CURRENCY_ADD: 'UI_CLAIM_CURRENCY_ADD',
  CLAIM_CURRENCY_EDIT: 'UI_CLAIM_CURRENCY_EDIT',
  CLAIM_CURRENCY_DEL: 'UI_CLAIM_CURRENCY_DEL',
  CLAIM_APPROVAL_VIEW: 'UI_CLAIM_APPROVAL_VIEW',
  CLAIM_APPROVAL_ADD: 'UI_CLAIM_APPROVAL_ADD',
  CLAIM_BASIC_SETTING_VIEW: 'UI_CLAIM_BASIC_SETTING_VIEW',
  CLAIM_BASIC_SETTING_ADD: 'UI_CLAIM_BASIC_SETTING_ADD',
  CLAIM_REPORT_VIEW: 'UI_CLAIM_REPORT_VIEW',
  CLAIM_REPORT_EDIT: 'UI_CLAIM_REPORT_EDIT',
  APP_CENTER_VIEW: 'UI_APP_CENTER_VIEW',
  APP_CENTER_EDIT: 'UI_APP_CENTER_EDIT',
  APP_CENTER_REVIEW_VIEW: 'UI_APP_CENTER_REVIEW_VIEW',
  APP_CENTER_REVIEW_EDIT: 'UI_APP_CENTER_REVIEW_EDIT',
  CHECK_IN_VIEW: 'UI_CHECK_IN_VIEW',
  CHECK_IN_EXPORT: 'UI_CHECK_IN_EXPORT',
  EMPLOYEE_ACCOUNT_SETTING_VIEW: 'UI_EMPLOYEE_ACCOUNT_SETTING_VIEW',
  TRUSTED_DEVICE_VIEW: 'UI_GOVERNANCE_TRUSTED_DEVICE_VIEW',
  CUSTOM_FIELD_VIEW: 'UI_CUSTOM_FIELD_VIEW',
  CUSTOM_FIELD_ADD: 'UI_CUSTOM_FIELD_ADD',
  CUSTOM_FIELD_EDIT: 'UI_CUSTOM_FIELD_EDIT',
  CUSTOM_FIELD_DEL: 'UI_CUSTOM_FIELD_DEL',
  DATA_LOSS_PREVENTION_VIEW: 'UI_GOVERNANCE_DLP_VIEW',
  GOVERNANCE_CHAT_PERM_VIEW: 'UI_GOVERNANCE_CHAT_PERM_VIEW',
  ORG_CHART_VISIBILITY_MANAGE: 'UI_ORG_CHART_VISIBILITY_MANAGE',
  UI_USER_GROUP_MANAGE: 'UI_USER_GROUP_MANAGE',
};

export const ROLE_CONFIG = {
  VIEW_DEPARTMENT: 0,
  ADD_EDIT_EMPLOYEE: 1,
  VIEW_EMPLOYEE_PROFILE: 2,
  DELETE_EMPLOYEE: 3,
  UPLOAD_EMPLOYEE_PHOTO: 4,
  IMPORT_EMPLOYEE: 5,
  EXPORT_EMPLOYEE: 6,
  ADD_EDIT_DEPARTMENT: 7,
  DELETE_DEPARTMENT: 9,
  VIEW_JOB_TITLE: 10,
  ADD_EDIT_JOB_TITLE: 11,
  DELETE_JOB_TITLE: 12,
  VIEW_OFFICE: 13,
  ADD_EDIT_OFFICE: 14,
  DELETE_OFFICE: 15,
  VIEW_SETTING_GENERAL: 16,
  VIEW_ADMIN_ROLE: 22,
  ADD_EDIT_ADMIN: 23,
  TOGGLE_ADMIN_ACTIVE: 24,
  DELETE_ADMIN: 26,
  MANAGE_BROADCAST: 27,
  VIEW_PUBLIC_ACCOUNT_BROADCAST: 28,
  ADD_EDIT_PUBLIC_ACCOUNT_MANAGE_BROADCAST: 29,
  DELETE_PUBLIC_ACCOUNT: 30,
  MASS_DELETE_EMPLOYEE: 31,
  VIEW_PAYROLL: 32,
  ADD_EDIT_PAYROLL: 33,
  DELETE_PAYROLL: 34,
  VIEW_RANK: 35,
  ADD_EDIT_RANK: 36,
  DELETE_RANK: 37,
  IMPORT_DEPARTMENT: 38,
  IMPORT_JOB_TITLE: 39,
  IMPORT_OFFICE: 40,
  IMPORT_PAYROLL_COMPANY: 41,
  IMPORT_RANK: 42,
  WORK_CALENDAR: 43,
  LEAVE_POLICY: 44,
  LEAVE_APPROVAL: 45,
  LEAVE_SUMMARY: 46,
  VIEW_BROADCAST: 47,
  ATTENDANCE_WORK_SHIFT: 48,
  ATTENDANCE_APPROVAL: 49,
  ATTENDANCE_SUMMARY: 50,
  VIEW_CLAIM_CATEGORY: 51,
  MANAGE_CLAIM_CATEGORY: 52,
  VIEW_CLAIM_APPROVAL: 53,
  MANAGE_CLAIM_APPROVAL: 54,
  VIEW_CLAIM_BASIC_SETTING: 55,
  MANAGE_CLAIM_BASIC_SETTING: 56,
  VIEW_CLAIM_REPORT: 57,
  MANAGE_CLAIM_REPORT: 58,
  APP_CENTER: 59,
  APP_CENTER_DEVELOPER: 60,
  VIEW_CHECK_IN: 61,
  EXPORT_CHECK_IN: 62,
  APP_CENTER_RELEASE_MANAGER: 63,
  TRUSTED_DEVICE_MANAGE: 67,
  CUSTOM_FIELD_VIEW: 68,
  CUSTOM_FIELD_ADD_OR_EDIT: 69,
  CUSTOM_FIELD_DEL: 70,
  DATA_LOST_PREVENTION_MANAGE: 71,
  ORG_CHART_VISIBILITY_MANAGE: 72,
  UI_USER_GROUP_MANAGE: 73,
  CHAT_ACCESS_PERMISSIONS_MANAGE: 74,
};

export const EMPLOYEE_FIELDS_CONFIG = {
  NAME: 0,
  AVATAR: 1,
  EMAIL: 2,
  PHONE: 3,
  ID: 4,
  JOB_TITLE: 5,
  DEPARTMENT: 6,
  OFFICE: 7,
  JOIN_DATE: 8,
  RANK: 9,
  REPORT: 10,
  PAYROLL: 11,
  GENDER: 12,
  PERSONAL_EMAIL: 13,
  MARITAL: 14,
  BIRTH: 15,
  ADDRESS: 16,
};

// export const EMPLOYEE_FIELDS_DEFAULT = [0, 1, 2, 4, 5, 6];
export const EMPLOYEE_FIELDS_DEFAULT = [];

export const DEPARTMENT_SCOPE_CONFIG = {
  ALL: 0,
  MINE_SUB: 1,
  MINE_ONLY: 2,
  WHITELIST: 3,
  NONE: 4,
};

export const COUNTRY_CODE = [
  { prefix: '+1', name: 'United States', iso: 'US' },
  { prefix: '+7', name: 'Russia', iso: 'RU' },
  // { prefix: '+7', name: 'Kazakhstan', iso: 'KZ' },
  { prefix: '+20', name: 'Egypt', iso: 'EG' },
  { prefix: '+27', name: 'South Africa', iso: 'ZA' },
  { prefix: '+30', name: 'Greece', iso: 'GR' },
  { prefix: '+31', name: 'Netherlands', iso: 'NL' },
  { prefix: '+32', name: 'Belgium', iso: 'BE' },
  { prefix: '+33', name: 'France', iso: 'FR' },
  { prefix: '+34', name: 'Spain', iso: 'ES' },
  { prefix: '+36', name: 'Hungary', iso: 'HU' },
  { prefix: '+39', name: 'Italy', iso: 'IT' },
  { prefix: '+40', name: 'Romania', iso: 'RO' },
  { prefix: '+41', name: 'Switzerland', iso: 'CH' },
  { prefix: '+43', name: 'Austria', iso: 'AT' },
  { prefix: '+44', name: 'United Kingdom', iso: '' },
  // { prefix: '+44', name: 'Guernsey', iso: 'GG' },
  // { prefix: '+44', name: 'Isle of Man', iso: 'IM' },
  // { prefix: '+44', name: 'Jersey', iso: 'JE' },
  { prefix: '+45', name: 'Denmark', iso: 'DK' },
  { prefix: '+46', name: 'Sweden', iso: 'SE' },
  { prefix: '+47', name: 'Norway', iso: '' },
  // { prefix: '+47', name: 'Svalbard and Jan Mayen', iso: '' },
  { prefix: '+48', name: 'Poland', iso: 'PL' },
  { prefix: '+49', name: 'Germany', iso: 'DE' },
  { prefix: '+51', name: 'Peru', iso: 'PE' },
  { prefix: '+52', name: 'Mexico', iso: 'MX' },
  { prefix: '+53', name: 'Cuba', iso: 'CU' },
  { prefix: '+54', name: 'Argentina', iso: 'AR' },
  { prefix: '+55', name: 'Brazil', iso: 'BR' },
  { prefix: '+56', name: 'Chile', iso: 'CL' },
  { prefix: '+57', name: 'Colombia', iso: 'CO' },
  { prefix: '+58', name: 'Venezuela', iso: 'VE' },
  { prefix: '+60', name: 'Malaysia', iso: 'MY' },
  { prefix: '+61', name: 'Australia', iso: 'AU' },
  // { prefix: '+61', name: 'Cocos Islands', iso:'CC' },
  // { prefix: '+61', name: 'Christmas Island', iso: 'CX },
  { prefix: '+62', name: 'Indonesia', iso: 'ID' },
  { prefix: '+63', name: 'Philippines', iso: 'PH' },
  { prefix: '+64', name: 'New Zealand', iso: 'NZ' },
  { prefix: '+65', name: 'Singapore', iso: 'SG' },
  { prefix: '+66', name: 'Thailand', iso: 'TH' },
  { prefix: '+81', name: 'Japan', iso: 'JP' },
  { prefix: '+82', name: 'Korea', iso: 'KR' },
  { prefix: '+84', name: 'Vietnam', iso: 'VN' },
  { prefix: '+86', name: 'China', iso: 'CN' },
  { prefix: '+90', name: 'Turkey', iso: 'TR' },
  { prefix: '+91', name: 'India', iso: 'IN' },
  { prefix: '+92', name: 'Pakistan', iso: 'PK' },
  { prefix: '+93', name: 'Afghanistan', iso: 'AF' },
  { prefix: '+94', name: 'Sri Lanka', iso: 'LK' },
  { prefix: '+95', name: 'Myanmar', iso: 'MM' },
  { prefix: '+98', name: 'Iran', iso: 'IR' },
  { prefix: '+211', name: 'South Sudan', iso: 'SS' },
  { prefix: '+212', name: 'Morocco', iso: 'MA' },
  // { prefix: '+212', name: 'Western Sahara', iso: 'EH' },
  { prefix: '+213', name: 'Algeria', iso: 'DZ' },
  { prefix: '+216', name: 'Tunisia', iso: 'TN' },
  { prefix: '+218', name: 'Libya', iso: 'LY' },
  { prefix: '+220', name: 'Gambia', iso: 'GM' },
  { prefix: '+221', name: 'Senegal', iso: 'SN' },
  { prefix: '+222', name: 'Mauritania', iso: 'MR' },
  { prefix: '+223', name: 'Mali', iso: 'ML' },
  { prefix: '+224', name: 'Guinea', iso: 'GN' },
  { prefix: '+225', name: 'Ivory Coast', iso: 'CI' },
  { prefix: '+226', name: 'Burkina Faso', iso: 'BF' },
  { prefix: '+227', name: 'Niger', iso: 'NE' },
  { prefix: '+228', name: 'Togo', iso: 'TG' },
  { prefix: '+229', name: 'Benin', iso: 'BJ' },
  { prefix: '+230', name: 'Mauritius', iso: 'MU' },
  { prefix: '+231', name: 'Liberia', iso: 'LR' },
  { prefix: '+232', name: 'Sierra Leone', iso: 'SL' },
  { prefix: '+233', name: 'Ghana', iso: 'GH' },
  { prefix: '+234', name: 'Nigeria', iso: 'NG' },
  { prefix: '+235', name: 'Chad', iso: 'TD' },
  { prefix: '+236', name: 'Central African Republic', iso: 'CF' },
  { prefix: '+237', name: 'Cameroon', iso: 'CM' },
  { prefix: '+238', name: 'Cape Verde', iso: 'CV' },
  { prefix: '+239', name: 'Sao Tome and Principe', iso: 'ST' },
  { prefix: '+240', name: 'Equatorial Guinea', iso: 'GQ' },
  { prefix: '+241', name: 'Gabon', iso: 'GA' },
  { prefix: '+242', name: 'Republic of the Congo', iso: 'CG' },
  { prefix: '+243', name: 'Democratic Republic of the Congo', iso: 'CD' },
  { prefix: '+244', name: 'Angola', iso: 'AO' },
  { prefix: '+245', name: 'Guinea-Bissau', iso: 'GW' },
  { prefix: '+246', name: 'British Indian Ocean Territory', iso: 'IO' },
  { prefix: '+247', name: 'Ascension Island', iso: 'AC' },
  { prefix: '+248', name: 'Seychelles', iso: 'SC' },
  { prefix: '+249', name: 'Sudan', iso: 'SD' },
  { prefix: '+250', name: 'Rwanda', iso: 'RW' },
  { prefix: '+251', name: 'Ethiopia', iso: 'ET' },
  { prefix: '+252', name: 'Somalia', iso: 'SO' },
  { prefix: '+253', name: 'Djibouti', iso: 'DJ' },
  { prefix: '+254', name: 'Kenya', iso: 'KE' },
  { prefix: '+255', name: 'Tanzania', iso: 'TZ' },
  { prefix: '+256', name: 'Uganda', iso: 'UG' },
  { prefix: '+257', name: 'Burundi', iso: 'BI' },
  { prefix: '+258', name: 'Mozambique', iso: 'MZ' },
  { prefix: '+260', name: 'Zambia', iso: 'ZM' },
  { prefix: '+261', name: 'Madagascar', iso: 'MG' },
  // { prefix: '+262', name: 'Reunion', iso: 'RE' },
  { prefix: '+262', name: 'Mayotte', iso: 'YT' },
  { prefix: '+263', name: 'Zimbabwe', iso: 'ZW' },
  { prefix: '+264', name: 'Namibia', iso: 'NA' },
  { prefix: '+265', name: 'Malawi', iso: 'MW' },
  { prefix: '+266', name: 'Lesotho', iso: 'LS' },
  { prefix: '+267', name: 'Botswana', iso: 'BW' },
  { prefix: '+268', name: 'Swaziland', iso: 'SZ' },
  { prefix: '+269', name: 'Comoros', iso: 'KM' },
  { prefix: '+290', name: 'Saint Helena', iso: 'SH' },
  { prefix: '+291', name: 'Eritrea', iso: 'ER' },
  { prefix: '+297', name: 'Aruba', iso: 'AW' },
  { prefix: '+298', name: 'Faroe Islands', iso: 'FO' },
  { prefix: '+299', name: 'Greenland', iso: 'GL' },
  { prefix: '+350', name: 'Gibraltar', iso: 'GI' },
  { prefix: '+351', name: 'Portugal', iso: 'PT' },
  { prefix: '+352', name: 'Luxembourg', iso: 'LU' },
  { prefix: '+353', name: 'Ireland', iso: 'IE' },
  { prefix: '+354', name: 'Iceland', iso: 'IS' },
  { prefix: '+355', name: 'Albania', iso: 'AL' },
  { prefix: '+356', name: 'Malta', iso: 'MT' },
  { prefix: '+357', name: 'Cyprus', iso: 'CY' },
  { prefix: '+358', name: 'Finland', iso: 'FI' },
  { prefix: '+359', name: 'Bulgaria', iso: 'BG' },
  { prefix: '+370', name: 'Lithuania', iso: 'LT' },
  { prefix: '+371', name: 'Latvia', iso: 'LV' },
  { prefix: '+372', name: 'Estonia', iso: 'EE' },
  { prefix: '+373', name: 'Moldova', iso: 'MD' },
  { prefix: '+374', name: 'Armenia', iso: 'AM' },
  { prefix: '+375', name: 'Belarus', iso: 'BY' },
  { prefix: '+376', name: 'Andorra', iso: 'AD' },
  { prefix: '+377', name: 'Monaco', iso: 'MC' },
  { prefix: '+378', name: 'San Marino', iso: 'SM' },
  { prefix: '+380', name: 'Ukraine', iso: 'UA' },
  { prefix: '+381', name: 'Serbia', iso: 'RS' },
  { prefix: '+382', name: 'Montenegro', iso: 'ME' },
  { prefix: '+383', name: 'Kosovo', iso: 'XK' },
  { prefix: '+385', name: 'Croatia', iso: 'HR' },
  { prefix: '+386', name: 'Slovenia', iso: 'SI' },
  { prefix: '+387', name: 'Bosnia and Herzegovina', iso: 'BA' },
  { prefix: '+389', name: 'Macedonia', iso: 'MK' },
  { prefix: '+420', name: 'Czech Republic', iso: 'CZ' },
  { prefix: '+421', name: 'Slovakia', iso: 'SK' },
  { prefix: '+423', name: 'Liechtenstein', iso: 'LI' },
  { prefix: '+500', name: 'Falkland Islands', iso: 'FK' },
  { prefix: '+501', name: 'Belize', iso: 'BZ' },
  { prefix: '+502', name: 'Guatemala', iso: 'GT' },
  { prefix: '+503', name: 'El Salvador', iso: 'SV' },
  { prefix: '+504', name: 'Honduras', iso: 'HN' },
  { prefix: '+505', name: 'Nicaragua', iso: 'NI' },
  { prefix: '+506', name: 'Costa Rica', iso: 'CR' },
  { prefix: '+507', name: 'Panama', iso: 'PA' },
  { prefix: '+508', name: 'Saint Pierre and Miquelon', iso: 'PM' },
  { prefix: '+509', name: 'Haiti', iso: 'HT' },
  // { prefix: '+590', name: 'Saint Barthelemy', iso: 'BL' },
  { prefix: '+590', name: 'Saint Martin', iso: 'MF' },
  { prefix: '+591', name: 'Bolivia', iso: 'BO' },
  { prefix: '+592', name: 'Guyana', iso: 'GY' },
  { prefix: '+593', name: 'Ecuador', iso: 'EC' },
  { prefix: '+594', name: 'French Guiana', iso: 'GF' },
  { prefix: '+595', name: 'Paraguay', iso: 'PY' },
  { prefix: '+596', name: 'Martinique', iso: 'MQ' },
  { prefix: '+597', name: 'Suriname', iso: 'SR' },
  { prefix: '+598', name: 'Uruguay', iso: 'UY' },
  { prefix: '+599', name: 'Curacao', iso: 'CW' },
  { prefix: '+670', name: 'East Timor', iso: 'TL' },
  { prefix: '+672', name: 'Antarctica', iso: 'AQ' },
  { prefix: '+673', name: 'Brunei', iso: 'BN' },
  { prefix: '+674', name: 'Nauru', iso: 'NR' },
  { prefix: '+675', name: 'Papua New Guinea', iso: 'PG' },
  { prefix: '+676', name: 'Tonga', iso: 'TO' },
  { prefix: '+677', name: 'Solomon Islands', iso: 'SB' },
  { prefix: '+678', name: 'Vanuatu', iso: 'VU' },
  { prefix: '+679', name: 'Fiji', iso: 'FJ' },
  { prefix: '+680', name: 'Palau', iso: 'PW' },
  { prefix: '+681', name: 'Wallis and Futuna', iso: 'WF' },
  { prefix: '+682', name: 'Cook Islands', iso: 'CK' },
  { prefix: '+683', name: 'Niue', iso: 'NU' },
  { prefix: '+685', name: 'Samoa', iso: 'WS' },
  { prefix: '+686', name: 'Kiribati', iso: 'KI' },
  { prefix: '+687', name: 'New Caledonia', iso: 'NC' },
  { prefix: '+688', name: 'Tuvalu', iso: 'TV' },
  { prefix: '+689', name: 'French Polynesia', iso: 'PF' },
  { prefix: '+690', name: 'Tokelau', iso: 'TK' },
  { prefix: '+691', name: 'Micronesia', iso: 'FM' },
  { prefix: '+692', name: 'Marshall Islands', iso: 'MH' },
  { prefix: '+850', name: 'North Korea', iso: 'KP' },
  { prefix: '+852', name: 'Hong Kong', iso: 'HK' },
  { prefix: '+853', name: 'Macau', iso: 'MO' },
  { prefix: '+855', name: 'Cambodia', iso: 'KH' },
  { prefix: '+856', name: 'Laos', iso: 'LA' },
  { prefix: '+880', name: 'Bangladesh', iso: 'BD' },
  { prefix: '+886', name: 'Taiwan', iso: 'TW' },
  { prefix: '+960', name: 'Maldives', iso: 'MV' },
  { prefix: '+961', name: 'Lebanon', iso: 'LB' },
  { prefix: '+962', name: 'Jordan', iso: 'JO' },
  { prefix: '+963', name: 'Syria', iso: 'SY' },
  { prefix: '+964', name: 'Iraq', iso: 'IQ' },
  { prefix: '+965', name: 'Kuwait', iso: 'KW' },
  { prefix: '+966', name: 'Saudi Arabia', iso: 'SA' },
  { prefix: '+967', name: 'Yemen', iso: 'YE' },
  { prefix: '+968', name: 'Oman', iso: 'OM' },
  { prefix: '+970', name: 'Palestine', iso: 'PS' },
  { prefix: '+971', name: 'United Arab Emirates', iso: 'AE' },
  { prefix: '+972', name: 'Israel', iso: 'IL' },
  { prefix: '+973', name: 'Bahrain', iso: 'BH' },
  { prefix: '+974', name: 'Qatar', iso: 'QA' },
  { prefix: '+975', name: 'Bhutan', iso: 'BT' },
  { prefix: '+976', name: 'Mongolia', iso: 'MN' },
  { prefix: '+977', name: 'Nepal', iso: 'NP' },
  { prefix: '+992', name: 'Tajikistan', iso: 'TJ' },
  { prefix: '+993', name: 'Turkmenistan', iso: 'TM' },
  { prefix: '+994', name: 'Azerbaijan', iso: 'AZ' },
  { prefix: '+995', name: 'Georgia', iso: 'GE' },
  { prefix: '+996', name: 'Kyrgyzstan', iso: 'KG' },
  { prefix: '+998', name: 'Uzbekistan', iso: 'UZ' },
];

export const CLOCK_IN_OUT_METHOD = {
  NOT_REQUIRED: 0,
  GPS_ONLY: 1,
  WIFI_ONLY: 2,
  GPS_OR_WIFI: 3,
  GPS_AND_WIFI: 4,
};

export const CLOCK_NUM = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

export const CLOCK_TYPE = {
  CLOCK_IN: 1,
  CLOCK_OUT: 2,
};

export const ATTENDANCE_STATUS = {
  NO_RECORD: 0,
  NORMAL: 1,
  LATE_IN: 2,
  EARLY_OUT: 3,
  INVALID: 4,
  NOT_REQUIRED: 5,
  AUTO: 6,
};

export const ATTENDANCE_DAILY_RESULT = {
  PENDING: 0,
  NORMAL: 1,
  ABNORMAL: 2,
  ABSENT: 3,
};

export const ATTENDANCE_DAILY_DATE_TYPE = {
  NO_CONFIG: 0,
  WORK_DAY: 1,
  ON_LEAVE: 2,
  HOLIDAY: 3,
  REST_DAY: 4,
  OPTIONAL_REST_DAY: 5,
};

export const ATTENDANCE_RECORD_RESULT = {
  VALID: 0,
  INVALID_NEW_RECORD: 1,
  INVALID_ADMIN_REVISION: 2,
  INVALID_CORRECTION_APPLICATION: 3,
};

export const APPLICATION_STATUS = {
  BLANK: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  TERMINATED: 4,
  WITHDRAWN: 5,
};

export const APPROVAL_NODE_TYPES = {
  BLANK: 0,
  START: 1,
  END: 2,
  CONDITION: 3,
  APPROVER: 4,
  CARBON_COPY: 5,
};

export const LEAVE_APPROVAL_TYPE = {
  BLANK: 0,
  APPROVAL: 1,
  CARBON_COPY: 2,
  SUBMIT: 3,
  WITHDRAW: 4,
};

export const APPROVAL_METHOD = {
  BLANK: 0,
  COUNTERSIGN: 1,
  PREEMPT: 2,
};

export const APPROVER_TYPE = {
  BLANK: 0,
  DEPARTMENT_HEAD: 1,
  REPORTING_MANAGER: 2,
  WHITELIST: 3,
  REPORTING_LINE: 4,
};

export const APPROVAL_STATUS = {
  INITIATED: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  TERMINATED: 4,
  SKIPPED: 5,
  SUBMITTED: 6,
  WITHDRAWN: 7,
  APPROVED_WITH_EDIT: 8,
};

export const APPROVAL_CHAIN_STATUS_REASON = {
  BLANK: 0,
  REPEATED_APPROVER: 1,
  APPROVER_SAME_AS_APPLICANT: 2,
  REPORTING_MANAGER_NOT_ASSIGNED: 3,
  DEPARTMENT_HEAD_IS_NOT_ASSIGNED: 4,
  APPROVER_OFF_BOARDING: 5,
};

export const REPORTING_LEVEL = {
  TOP_LEVEL: 0,
  FIRST_LEVEL: 1,
  SECOND_LEVEL: 2,
  THIRD_LEVEL: 3,
};

export const ONBOARD_REQUEST_STATUS = {
  PENDING: 0,
  ONBOARD: 1,
  REJECTED: 2,
  TERMINATED: 3,
};

export const LEAVE_APPROVAL_DURATION_TYPE = {
  BLANK: 0,
  GREATER_THAN_EQUAL: 1,
  GREATER_THAN: 2,
  LESS_THAN_EQUAL: 3,
  LESS_THAN: 4,
  EQUAL: 5,
  BETWEEN_RANGE: 6,
};

export const LEAVE_TYPE_PURPOSE = {
  DEFAULT: 0,
  CREDIT: 1,
};

export const CLAIM_TEMPLATE_LAYOUT = {
  VERTICAL: 0,
  HORIZONTAL: 1,
};

export const CLAIM_NOT_EDITABLE_REASON = {
  CLAIM_REPORT: 1,
  CLAIM_POLICY: 2,
  CURRENCY: 3,
  CLAIM_APPROVAL: 4,
};

export const CLAIM_AMOUNT_RANGE_TYPE = {
  BLANK: 0,
  GREATER_THAN_EQUAL: 1,
  GREATER_THAN: 2,
  LESS_THAN_EQUAL: 3,
  LESS_THAN: 4,
  EQUAL: 5,
  BETWEEN_RANGE: 6,
};

export const CLAIM_LIMIT_TYPE = {
  NOT_ALLOWED: -1,
  NO_LIMIT: 0,
  PER_TRANSACTION: 1,
  PER_DAY: 2,
  PER_MONTH: 3,
  PER_YEAR: 4,
};

export const CLAIM_VALIDATION_TYPE = {
  NA: 0,
  ERROR: 1,
  WARNING: 2,
};

export const CLAIM_PRORATE_TYPE = {
  NA: 0,
  YES: 1,
  NO: 2,
};

export const CLAIM_APPLICATION_STATUS = {
  BLANK: 0,
  WITHDRAWN: 1,
  RESUBMITTED: 2,
  PENDING_MANAGER: 3,
  PENDING_FINANCE: 4,
  PENDING_PAYMENT: 5,
  PAID_IN_FULL: 6,
  TERMINATED: 7,
  REJECTED_BY_FINANCE: 8,
  REJECTED: 9,
};

export const CLAIM_FORM_TEMPLATE = {
  GENERAL: 1,
  AIR_TICKET: 2,
  TAXI: 3,
  HOTEL: 4,
  MEDICAL_FEE: 5,
};

export const CATEGORY_TEMPLATE_FIELD_TYPE = {
  TEXT_FIELD: 1,
  INTEGER_FIELD: 2,
  FLOATING_POINT_FIELD: 3,
  PICKER: 4,
  DATE_PICKER: 5,
  DATE_RANGE: 6,
  SUB_FORM_PICKER: 7,
};

export const CLAIM_APPLICATION_ENTRY_STATUS = {
  NORMAL: 0,
  FINANCE_EDITED: 1,
  FINANCE_ADDED: 2,
  FINANCE_DELETED: 3,
};

export const CLAIM_APPLICATION_APPROVAL_NODE_TYPE = {
  BLANK: 0,
  APPROVAL: 1,
  CARBON_COPY: 2,
  SUBMIT: 3,
  WITHDRAW: 4,
  RESUBMIT: 5,
  PAYMENT: 6,
};

export const CLAIM_APPLICATION_APPROVAL_STATUS = {
  INITIATED: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  TERMINATED: 4,
  SKIPPED: 5,
  SUBMITTED: 6,
  WITHDRAWN: 7,
  APPROVED_WITH_EDIT: 8,
  FINANCE_EDIT: 9,
  PENDING_PAYMENT: 10,
  PAID_IN_FULL: 11,
  PARTIAL_APPROVED: 12,
};

export const CLAIM_APPLICATION_APPROVER_TYPE = {
  BLANK: 0,
  DEPARTMENT_HEAD: 1,
  REPORTING_MANAGER: 2,
  WHITELIST: 3,
  REPORTING_LINE: 4,
  FINANCE: 5,
};

export const APP_CENTER_APP_STATUS = {
  NOT_CONFIGURED: 0,
  ONLINE: 1,
  MAINTENANCE: 2,
  OFFLINE: 3,
};

export const APP_CENTER_REVIEW_STATUS = {
  DRAFT: 0,
  PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
};

export const enum APP_CENTER_PERMISSION {
  GET_DEPARTMENT_LIST = 'get.department.list',
  GET_DEPARTMENT_EMPLOYEES = 'get.employee.list',
  GET_EMPLOYEE_PROFILE = 'get.employee.profile',
  GET_SEAGROUP_HRIS_PROFILE = 'get.sea.hris.profile',
  CHECK_EMPLOYEE_EXISTENCE = 'check.employee.existence',
  SEND_PLAIN_TEXT_SERVICE_NOTICE = 'send.plain.channel.notice',
  SEND_TEMPLATE_SERVICE_NOTICE = 'send.template.channel.notice',
  SEND_PLAIN_TEXT_SERVICE_NOTICE_INTERNATIONALIZED = 'send.plain.channel.notice.i18n',
  SEND_TEMPLATE_SERVICE_NOTICE_INTERNATIONALIZED = 'send.template.channel.notice.i18n',
  GET_LANGUAGE_PREFERENCE = 'get.employee.language.preference',
  GET_WORK_GROUP_DETAILS = 'get.work.group.details',
  GET_WORK_GROUP_EMPLOYEES = 'get.work.group.employees',
  GET_ATTENDANCE_DAILY_REPORT = 'get.attendance.daily.report',
  GET_ATTENDANCE_MONTHLY_REPORT = 'get.attendance.monthly.report',
  GET_LEAVE_BALANCES = 'get.leave.balances',
  GET_LEAVE_APPLICATIONS = 'get.leave.applications',
  GET_LEAVE_CREDIT_APPLICATIONS = 'get.leave.credit.applications',
  GET_CLAIM_REPORTS = 'get.claim.reports',
  GET_CLAIM_REPORT_ATTACHMENTS = 'get.claim.report.attachments',
  GET_CHECKIN_RECORDS = 'get.checkin.records',
  SET_DISCOVER_PAGE_NOTIFICATION_BADGE = 'update.app.badge',
  UPDATE_APPROVAL_ITEM = 'update.approval.item',
  MANAGE_EMPLOYEE = 'access.oa.employee',
  GET_EMPLOYEE_CODE = 'get.employee.code.with.email',
  CREATE_APPROVAL_ITEM = 'create.approval.item',
  RETRIEVE_APPROVAL_ITEM = 'retrieve.approval.item',
  DELETE_APPROVAL_ITEM = 'delete.approval.item',
  MANAGE_DEPARTMENT_SYNC = 'access.oa.department',
  BOT_SUBSCRIBER = 'send.bot.single.chat.msg',
  UPDATE_EMPLOYEE_AVATAR = 'update.oa.employee.avatar',
  GET_EMPLOYEE_CODE_BY_CUSTOM_FIELD = 'get.employee.code.by.custom.field',
  SEND_MESSAGE_TO_GROUP_CHAT = 'send.bot.group.chat.message',
  GET_GROUP_INFO = 'get.group.chat.info',
  GET_CHAT_HISTORY = 'get.chat.history',
  GET_EMPLOYEE_STATUS = 'get.employee.status',
}

export const FILE_TYPES = {
  IMG: 0,
  PDF: 1,
};

export const FS_UPLOAD_FILE_TYPE = {
  UNKNOWN: 0,
  JPEG: 1,
  PNG: 2,
  VOICE: 3,
  DOODLE: 4,
  GIF: 5,
  VIDEO: 6,
  FILE: 7,
};

export const RESOURCE_TYPE = {
  DEPARTMENT_GROUP_OWNER: 0,
  ADMIN_ROLE: 1,
  DEPARTMENT_LEAD: 2,
  REPORTING_MANAGER: 3,
  SPECIFIC_APPROVER: 4,
  LEAVE_AND_ATTENDANCE: 5,
  CLAIM: 6,
  USER_GROUP_CHAT_GROUP_OWNER: 7,
};

export const ARRANGEMENT_STATUS = {
  ARRANGED: 0,
  DONE: 1,
  UNARRANGED: 2,
};

export const UNARRANGED_TYPE = {
  EMPTY_RECEIVER: 0,
  NEW_ASSIGNMENT: 1,
};

export const APPROVAL_TYPE = {
  LEAVE: 1,
  CREDIT: 2,
  ATTENDANCE_CORRECTION: 3,
  CLAIM: 4,
  CUSTOM_APP: 5,
};

export const QUICK_ACCESS_CODES = {
  DEPARTMENT_AND_EMPLOYEE: 'O1',
  ADD_EMPLOYEE: 'O2',
  INVITE_EMPLOYEE: 'O3',
  IMPORT_EMPLOYEE: 'O4',
  FIELD_CONFIGURATION: 'O5',
  LEAVE_AND_ATTENDANCE: 'A1',
  CLAIM: 'A2',
  PUBLIC_ACCOUNT: 'A3',
  CHECK_IN: 'A4',
  APP_CENTER: 'A5',
  ORGANIZATION_INFO: 'S1',
  ADMIN_LIST: 'S2',
  ROLES: 'S3',
};

export const APP_FEATURE_CODE = {
  APPROVAL_CENTER: '1001',
  LEAVE_APPLICATION: '1002',
  ATTENDANCE: '1003',
  CLAIM: '1004',
  CHECK_IN: '1005',
  ANNOUNCEMENT: '1006',
};

export const APP_CENTER_APP_TYPE = {
  CUSTOM: 0,
  SEATALK: 1,
};

export const APP_CENTER_TAB = {
  APP_LIST: 1,
  RELEASE_REQUEST: 2,
  CUSTOM_APP: 3,
};

export const APP_PLATFORM_TYPE = {
  DISABLED: -1,
  REACT_NATIVE: 1,
  WEB: 2,
  CUSTOM_PROCESS: 3,
};

export const DESKTOP_TYPE = {
  DISABLED: -1,
  WEB: 2,
};

export const BOT_TYPE = {
  DISABLED: -1,
  ENABLED: 1,
};

export const ANNOUNCEMENT_MEDIA_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  LINK: 3,
  AUDIO: 4,
  VIDEO: 5,
  FILE: 6,
  EXCEL: 7,
  ARTICLE: 8,
};

export const ANNOUNCEMENT_STATUS = {
  DRAFT: 0,
  PENDING: 1,
  DELIVERED: 2,
  FAILED: 3,
  RECALLED: 4,
  DELIVERING: 7,
};

export const ANNOUNCEMENT_AUDIENCE_SCOPE = {
  ALL: 0,
  SPECIFIED: 1,
};

export const GENERAL_ERROR = {
  UNAUTHENTICATED: 100,
  INVALID_FORMAT: 101,
  INSUFFICIENT_PERMISSION: 102,
  ACCOUNT_SUSPENDED: 103,
  ACCOUNT_NOT_FOUND: 104,
  COMPANY_NOT_FOUND: 105,
  COMPANY_BEYOND_SCOPE: 106,
  DATABASE_ERROR: 200,
  INTERNAL_SERVER_ERROR: 201,
};

export const WORK_GROUP_ATTENDANCE_TYPE = {
  OFF: 0,
  REGULAR: 1,
  MANUAL: 2,
};

export const WORK_SHIFT_TYPE = {
  FIXED_TIME: 0,
  FLEXIBLE_TIME: 1,
  FREE_CLOCK_IN_OUT: 2,
};

export const AUTO_CLOCKING_CONFIG = {
  OFF: 0,
  ALLOWED: 1,
};

export const TRANSFER_ADMIN_PURPOSE = {
  PRIMARY_SUPER_ADMIN_TRANSFER: 1,
};

export const TUTORIAL_TYPE = {
  ORGANIZATION: 0,
  LEAVE: 1,
  ATTENDANCE: 2,
};

export const ROLE_RANK = {
  SEATALK_ADMIN: 100,
  SUPER_ADMIN: 200,
  CUSTOMIZED_ADMIN: 300,
};

export const FORM_FIELD_TYPE = {
  SINGLE_LINE_TEXT: 1,
  MULTI_LINE_TEXT: 2,
  DESCRIPTION: 3,
  NUMBER: 4,
  SINGLE_SELECT: 5,
  MULTIPLE_SELECT: 6,
  DEPARTMENT: 7,
  EMPLOYEE: 8,
  DATE_TIME: 9,
  DATE_TIME_RANGE: 10,
  ATTACHMENT: 11,
};

export const THOUSAND_SEPARATOR_FORMAT = {
  NONE: 0,
  COMMA: 1,
  DOT: 2,
};

export const COMPARISON_OPERATOR = {
  NONE: 0,
  GREATER_THAN_EQUAL: 1,
  GREATER_THAN: 2,
  LESS_THAN: 3,
  LESS_THAN_EQUAL: 4,
};

export const DATE_TIME_FORMAT = {
  DD_MMM_YYYY: 1,
  DD_MMM_YYYY_HH_MM: 2,
};

export const LIST_VIEW_DESIGN_TYPE = {
  DEFAULT: 1,
  CUSTOMIZED: 2,
};

export const APP_ACCESS_CONTROL_CODE = {
  CLAIM: 'claim',
  LEAVE_ATTENDANCE: 'leatt',
  ANNOUNCEMENT: 'announcement',
  CHECK_IN: 'checkin',
};

export const USER_ACTION = {
  CLICK_HELP_CENTER: 0,
};

export const FEATURE_TOGGLE = {
  CAN_SEE_CUSTOM_APP: IS_LIVE ? '1501868688834301952' : '1498933731581235200',
  CAN_SEE_GOVERNANCE: '18008577774601009279',
  CAN_SEE_CUSTOM_FIELD: '13849202424011890070',
  CAN_SEE_AVATAR_BADGE: '180233409933640727',
  CAN_SEE_CHAT_AUDIT: '11088453685343669339',
  CAN_SEE_AUDIT_LOG: IS_LIVE ? '10728415371955341113' : '10728415371955341113',
};

export const GTAG_ID = IS_LIVE ? 'G-4NGNEHSBPZ' : 'G-ZNNDCRXCG9';

/**
 * 前端标识'新增/已删除/现有'三种数据状态
 */
export const enum DataStatus {
  /** 新增 */
  Added,
  /** 已删除 */
  Deleted,
  /** 现有 */
  Existed,
}

export const enum RequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Resolved = 'resolved',
  Error = 'error',
}

export const SOP_BASE_URL = IS_LIVE
  ? 'https://open.seatalk.io'
  : 'https://open.test.seatalk.io';

/**
 * app完整类型
 * 结合appType(app来源)和isPublic
 */
export enum AppFullType {
  SOPPrivateApp,
  SOPPublicApp,
  OAPublicApp,
}

export const REG_EXP = {
  /** 仅数字和英文字母 */
  ONLY_NUMBER_AND_LETTERS: /^[A-Za-z0-9]+$/,
};

export enum PageType {
  Edit,
  Detail,
  Create,
}

/** FIXME antd的button，icon和文案不居中，暂时没有看出是哪里被改到了，先手动修复 */
export const ANTD_BUTTON_ICON_CENTER_STYLE = {
  display: 'flex',
  alignItems: 'center',
};
export const EMPTY_ERROR_MESSAGE = '　';
